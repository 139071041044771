import React from "react";
import styled from "styled-components";

function Home() {
  return (
    <Wrapper id="section1">
      <p>my name is Hannah</p>
      <p>I'm a web designer and filmmaker in Tiohtià꞉ke / Montreal</p>
      <p>focused on accessibility and play</p>
      <p>and always learning</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  padding: 100px 0px;
  scroll-behavior: smooth;
  line-height: 10px;
`;

export default Home;
