import React from "react";
import styled from "styled-components";

import HoverVideoPlayer from "react-hover-video-player";
import coverPhoto from "../assets/dreamy1.png";
import video from "../assets/dreams1.mp4";
import dreams2 from "../assets/dreams2.mp4";
import dreams2jpg from "../assets/dreams2.jpg";
import dreams3 from "../assets/dreams3.mp4";
import dreams3jpg from "../assets/dreams3.jpg";
import florida1 from "../assets/florida1.mp4";
import florida6 from "../assets/florida6.jpg";
import florida2 from "../assets/florida2.mp4";
import florida2jpg from "../assets/florida2.png";
import florida3 from "../assets/florida3.mp4";
import florida3jpg from "../assets/florida3.jpg";
import MVI1 from "../assets/MVI1.mp4";
import MVI1jpg from "../assets/MVI1.jpg";
import MVI2 from "../assets/MVI2.mp4";
import MVI2jpg from "../assets/MVI2.jpg";
import MVI3 from "../assets/MVI3.mp4";
import MVI3jpg from "../assets/MVI3.jpg";

function Video() {
  return (
    <Wrapper>
      <VideoContainer>
        <TextContainerLeft>
          <Description>Untitled (2017 - Present)</Description>
          <Description>Filmed on Canon Point-And-Shoot</Description>
        </TextContainerLeft>
        <VideoWrapper>
          <HoverVideoPlayer
            videoSrc={florida1}
            pausedOverlay={
              <img
                src={florida6}
                alt=""
                style={{
                  // Make the image expand to cover the video's dimensions
                  width: "75%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            }
            loadingOverlay={
              <div className="loading-overlay">
                <div className="loading-spinner" />
              </div>
            }
          />
        </VideoWrapper>
        <VideoWrapper>
          <HoverVideoPlayer
            videoSrc={florida2}
            pausedOverlay={
              <img
                src={florida2jpg}
                alt=""
                style={{
                  // Make the image expand to cover the video's dimensions
                  width: "75%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            }
            loadingOverlay={
              <div className="loading-overlay">
                <div className="loading-spinner" />
              </div>
            }
          />
        </VideoWrapper>
        <VideoWrapper>
          <HoverVideoPlayer
            videoSrc={florida3}
            pausedOverlay={
              <img
                src={florida3jpg}
                alt=""
                style={{
                  // Make the image expand to cover the video's dimensions
                  width: "75%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            }
            loadingOverlay={
              <div className="loading-overlay">
                <div className="loading-spinner" />
              </div>
            }
          />
        </VideoWrapper>
      </VideoContainer>
      <VideoContainer>
        <VideoWrapper>
          <HoverVideoPlayer
            videoSrc={MVI1}
            pausedOverlay={
              <img
                src={MVI1jpg}
                alt=""
                style={{
                  // Make the image expand to cover the video's dimensions
                  width: "75%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            }
            loadingOverlay={
              <div className="loading-overlay">
                <div className="loading-spinner" />
              </div>
            }
          />
        </VideoWrapper>
        <VideoWrapper>
          <HoverVideoPlayer
            videoSrc={MVI2}
            pausedOverlay={
              <img
                src={MVI2jpg}
                alt=""
                style={{
                  // Make the image expand to cover the video's dimensions
                  width: "75%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            }
            loadingOverlay={
              <div className="loading-overlay">
                <div className="loading-spinner" />
              </div>
            }
          />
        </VideoWrapper>
        <VideoWrapper>
          <HoverVideoPlayer
            videoSrc={MVI3}
            pausedOverlay={
              <img
                src={MVI3jpg}
                alt=""
                style={{
                  // Make the image expand to cover the video's dimensions
                  width: "75%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            }
            loadingOverlay={
              <div className="loading-overlay">
                <div className="loading-spinner" />
              </div>
            }
          />
        </VideoWrapper>
        <TextContainerRight>
          <Description>MVI 3743 (2018)</Description>
          <Description>YouTube Found Footage</Description>
          <Description>Screened at Neighborhood Film Festival 2018</Description>
        </TextContainerRight>
      </VideoContainer>
      <VideoContainer>
        <TextContainerLeft>
          <Description>IN MY DREAMS (2017)</Description>
          <Description>35mm Found Footage</Description>
          <Description>Screened at 44th CFF - DEEP FOCUS</Description>
        </TextContainerLeft>
        <VideoWrapper>
          <HoverVideoPlayer
            videoSrc={dreams3}
            pausedOverlay={
              <img
                src={dreams3jpg}
                alt=""
                style={{
                  // Make the image expand to cover the video's dimensions
                  width: "75%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            }
            loadingOverlay={
              <div className="loading-overlay">
                <div className="loading-spinner" />
              </div>
            }
          />
        </VideoWrapper>
        <VideoWrapper>
          <HoverVideoPlayer
            videoSrc={dreams2}
            pausedOverlay={
              <img
                src={dreams2jpg}
                alt=""
                style={{
                  // Make the image expand to cover the video's dimensions
                  width: "75%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            }
            loadingOverlay={
              <div className="loading-overlay">
                <div className="loading-spinner" />
              </div>
            }
          />
        </VideoWrapper>
        <VideoWrapper>
          <HoverVideoPlayer
            videoSrc={video}
            pausedOverlay={
              <img
                src={coverPhoto}
                alt=""
                style={{
                  // Make the image expand to cover the video's dimensions
                  width: "75%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            }
            loadingOverlay={
              <div className="loading-overlay">
                <div className="loading-spinner" />
              </div>
            }
          />
        </VideoWrapper>
      </VideoContainer>
      <p>e: hannah.silver96@gmail.com</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  padding: 40px;
`;

const VideoContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 0px;
`;

const VideoWrapper = styled.div`
  width: 25%;
  cursor: pointer;
`;

const TextContainerRight = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const TextContainerLeft = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  text-align: right;
`;

const Description = styled.p`
  margin: 5px;
  font-size: 95%;
  margin: 2px 13%;
`;

export default Video;
