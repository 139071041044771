import "./App.css";
import TextAnimation from "./components/pages/TextAnimation";
import Home from "./components/pages/Home";
import Video from "./components/pages/Video";
import styled from "styled-components";

function App() {
  return (
    <div className="App">
      <TextAnimation />
      <Home />
      <Video />
    </div>
  );
}

export default App;
