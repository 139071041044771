import React from "react";
import styled from "styled-components";
import P5 from "./p5.js";
import { Link, animateScroll as scroll } from "react-scroll";

function TextAnimation() {
  return (
    <Wrapper>
      <Background>
        <P5 />
      </Background>
      <Test>
        <Hi>hi :)</Hi>
        <LinkWrapper>
          <Link to="section1" smooth={true} cursor="pointer">
            ↓
          </Link>
        </LinkWrapper>
      </Test>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Hi = styled.h4`
  margin: 0;
`;

const LinkWrapper = styled.div`
  cursor: pointer;
  &:hover {
    color: #60ff52;
  }
`;

const Background = styled.div`
  position: absolute;
  margin: 0px;
  z-index: -9999;
`;

const Test = styled.div`
  position: absolute;
  width: 530px;
  height: 530px;
  border: black 40px solid;
  background-color: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 100px;
  text-align: center;
`;


export default TextAnimation;
