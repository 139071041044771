import { StarBorderPurple500 } from "@mui/icons-material";
import React from "react";
import Sketch from "react-p5";

var r;
var g;
var b;

export default (props) => {
  const setup = (p5, canvasParentRef) => {
    // use parent to render the canvas in this ref
    // (without that p5 will render the canvas outside of your component)
    p5.createCanvas(560, 560).parent(canvasParentRef);
    r = p5.random(255);
    g = p5.random(255);
    b = p5.random(255);
    p5.textSize(15);
    p5.fill(255);
  };

  const draw = (p5) => {
    p5.fill(r, g, b, 100);
    p5.stroke(b, r, g);
    p5.ellipse(p5.mouseX, p5.mouseY, 40);

    // NOTE: Do not use setState in the draw function or in functions that are executed
    // in the draw function...
    // please use normal variables or class properties for these purposes
  };

  const mousePressed = (p5) => {
    r = p5.random(200);
    g = p5.random(255);
    b = p5.random(255);
  };

  return <Sketch setup={setup} draw={draw} mousePressed={mousePressed} />;
};
